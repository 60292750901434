import { Company } from '@combateafraude/icons/general';
import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import './styles.less';
import { Button } from 'antd';

import NotAvailable from 'components/NotAvailable';
import { useTranslation } from 'react-i18next';
import useModalMoreInfos from '../../ModalMoreInfos';

import RenderFlagsOfCompany from '../../../../CompanyDetails/components/TabsComponent/CompanyInfo/components/RenderFlagsOfCompany';

const RelationshipField = ({ field, percentage }) => {
  const { t } = useTranslation('kyb-business-identity-relationship');

  if (!field) {
    return null;
  }

  return (
    <>
      <div className="kyb-relationship-list-field">
        <span className="field">{`${t(field)} ${percentage}`}</span>
      </div>
    </>
  );
};

const truncate = (str, length) => {
  if (!str) return <NotAvailable />;
  return str.length > length ? `${str.slice(0, length)}...` : str;
};

const isValidDate = (date) => {
  return date instanceof Date && !Number.isNaN(date.getTime());
};

export default memo(({ data }) => {
  const { label, country, address, status, latestAnnualAccounts, isUBO } = data;

  const { t: translate } = useTranslation();

  const countries = Array.isArray(country) ? [...country] : [country];
  let statusBadgeClass = '';
  if (status === 'NonActive') {
    statusBadgeClass = 'kyb-group-structure-status-badge-nonactive';
  } else if (status) {
    statusBadgeClass = 'kyb-group-structure-status-badge-active';
  }

  const { MoreInfosModal, openModal } = useModalMoreInfos();

  return (
    <div className="kyb-group-structure-node nodrag">
      {MoreInfosModal}
      <div className="kyb-group-structure-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Company height={20} width={20} className="tab-item-icon" />
          <div className="kyb-group-structure-flag-container">
            <span className="kyb-group-structure-has-shareholder">
              <RelationshipField field={data.field} percentage={data.percentage} />
            </span>
            <div className="kyb-group-structure-company-name">{label}</div>
          </div>
        </div>
        <div style={{ marginRight: '20px' }}>
          <RenderFlagsOfCompany countries={countries} />
        </div>
      </div>

      <div className="kyb-group-structure-info-section">
        <div className="kyb-group-structure-field-container">
          <div className="kyb-group-structure-label">
            {translate('pages.private.KYBCompany.pages.reactFlowTreeNode.address')}
          </div>
          <div className="kyb-group-structure-value">{address}</div>
        </div>

        <div className="kyb-group-structure-row">
          <div className="kyb-group-structure-field-container">
            <div className="kyb-group-structure-label">
              {translate('pages.private.KYBCompany.pages.reactFlowTreeNode.status')}
            </div>
            <div
              className={`kyb-group-structure-value kyb-group-structure-status-badge ${statusBadgeClass}`}
            >
              {status || <NotAvailable />}
            </div>
          </div>
          <div className="kyb-group-structure-field-container">
            <div className="kyb-group-structure-label">
              {translate('pages.private.KYBCompany.pages.reactFlowTreeNode.identifier')}
              {data?.identifiers?.length > 1 && (
                <Button
                  size="small"
                  className="kyb-company-popover-button"
                  onClick={() =>
                    openModal({ array: data?.identifiers, type: 'identifiers' })
                  }
                >
                  + {data?.identifiers?.length - 1}
                </Button>
              )}
            </div>
            <div className="kyb-group-structure-value">
              {truncate(data?.identifiers?.[0]?.value, 10)}
            </div>
          </div>
          {!isUBO && (
            <div className="kyb-group-structure-field-container">
              <div className="kyb-group-structure-label">
                {translate(
                  'pages.private.KYBCompany.pages.reactFlowTreeNode.latestFinancials'
                )}
              </div>
              <div className="kyb-group-structure-value">
                {latestAnnualAccounts && isValidDate(new Date(latestAnnualAccounts)) ? (
                  new Date(latestAnnualAccounts).toLocaleDateString()
                ) : (
                  <NotAvailable />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <>
        <Handle
          type="source"
          position="top"
          style={{ background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable
        />

        <Handle type="target" position="bottom" isConnectable />
        {/* {!data?.isLeaf ? (
          <Handle type="target" position="bottom" isConnectable />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="ubo-tag">UBO</div>
          </div>
        )} */}
      </>

      {data.isLeaf && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="ubo-tag">UBO</div>
        </div>
      )}
    </div>
  );
});
