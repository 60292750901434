/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Divider, Col } from 'antd';

import EmptyExecutionState from 'components/EmptyExecutionState';
import DottedLabel from 'components/DottedLabel';

import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import MediaExposureItemsCollapse from './components/MediaExposureItemsCollapse';
import { popularityLevelMappings, unpopularityLevelMappings } from './utils/mappings';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

import './styles.less';
import MediaExposureCard from '../../People/pfMediaProfileAndExposure/MediaExposureCard';

const NEGATIVE_SENTIMENT_LABELS = ['NEGATIVO', 'NEGATIVE', 'SLIGHTLY_NEGATIVE'];
const POSITIVE_SENTIMENT_LABELS = ['POSITIVO', 'POSITIVE', 'SLIGHTLY_POSITIVE'];

const I18N_BASE_PATH = 'components.dataSourceCards.mediaExposure.companies.index';

const PjMediaProfileAndExposure = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const mediaExposure = useMemo(() => source?.data || source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const queryWorked = useMemo(
    () =>
      statusCode === '01' ||
      source?.available ||
      Object.keys(source?.data || {}).length ||
      (Array.isArray(source?.data) && source?.data?.length),
    [statusCode, source]
  );

  const mediaExposureItems = useMemo(() => {
    const positiveItems = [];
    const negativeItems = [];
    const neutralItems = [];

    mediaExposure?.newsItems?.forEach((i) => {
      if (
        POSITIVE_SENTIMENT_LABELS.includes(
          i?.sentimentAnalysis?.label?.toString()?.toUpperCase()
        )
      ) {
        return positiveItems.push(i);
      }

      if (
        NEGATIVE_SENTIMENT_LABELS.includes(
          i?.sentimentAnalysis?.label?.toString()?.toUpperCase()
        )
      ) {
        return negativeItems.push(i);
      }

      return neutralItems.push(i);
    });

    return {
      positiveItems,
      neutralItems,
      negativeItems,
    };
  }, [mediaExposure]);

  return (
    <>
      <DataSourceCardCollapsePanel
        customId="media-exposure-component"
        title={translate(`${I18N_BASE_PATH}.title`)}
        icon="caf-ic_news"
        toPrint={toPrint}
      >
        <div className="flex flex-column no-mrg no-pdd">
          {queryWorked ? (
            <>
              <Row className="mrg-top-25">
                <ColumnTitleValue
                  span={9}
                  title={translate(`${I18N_BASE_PATH}.columns.mediaExposureLevel`)}
                >
                  <DottedLabel
                    type={
                      popularityLevelMappings[mediaExposure?.mediaExposureLevel]?.type ||
                      'default'
                    }
                    description={
                      translate(
                        popularityLevelMappings[mediaExposure?.mediaExposureLevel]
                          ?.description
                      ) || '-'
                    }
                  />
                </ColumnTitleValue>
                <ColumnTitleValue
                  span={7}
                  title={translate(`${I18N_BASE_PATH}.columns.celebrityLevel`)}
                >
                  <DottedLabel
                    type={
                      popularityLevelMappings[mediaExposure?.celebrityLevel]?.type ||
                      'default'
                    }
                    description={
                      translate(
                        popularityLevelMappings[mediaExposure?.celebrityLevel]
                          ?.description
                      ) || '-'
                    }
                  />
                </ColumnTitleValue>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.columns.unpopularityLevel`)}
                  span={8}
                >
                  <DottedLabel
                    type={
                      unpopularityLevelMappings[mediaExposure?.unpopularityLevel]?.type ||
                      'default'
                    }
                    description={
                      translate(
                        unpopularityLevelMappings[mediaExposure?.unpopularityLevel]
                          ?.description
                      ) || '-'
                    }
                  />
                </ColumnTitleValue>
              </Row>
              <Divider />
              <Row>
                <ColumnTitleValue
                  span={9}
                  title={translate(`${I18N_BASE_PATH}.columns.creationDate`)}
                >
                  <span>{i18nFormatDate(mediaExposure?.creationDate, 1) || '-'}</span>
                </ColumnTitleValue>
                <ColumnTitleValue
                  span={9}
                  title={translate(`${I18N_BASE_PATH}.columns.lastUpdateDate`)}
                >
                  <span>{i18nFormatDate(mediaExposure?.lastUpdateDate, 1) || '-'}</span>
                </ColumnTitleValue>
              </Row>

              {toPrint ? (
                <Row className="mrg-left-10 mrg-top-15">
                  {[
                    ...mediaExposureItems?.positiveItems,
                    ...mediaExposureItems?.neutralItems,
                    ...mediaExposureItems?.negativeItems,
                  ]?.map((media) => (
                    <MediaExposureCard key={media?.url} data={media} />
                  ))}
                </Row>
              ) : (
                <>
                  <Row className="mrg-top-20">
                    <Col span={24}>
                      <MediaExposureItemsCollapse
                        items={mediaExposureItems?.positiveItems}
                        title={translate(
                          `${I18N_BASE_PATH}.collapsePanelTitles.positiveItems`
                        )}
                        itemsCountType="primary"
                        toPrint={toPrint}
                      />
                    </Col>
                  </Row>
                  <Row className="mrg-top-20">
                    <Col span={24}>
                      <MediaExposureItemsCollapse
                        items={mediaExposureItems?.neutralItems}
                        title={translate(
                          `${I18N_BASE_PATH}.collapsePanelTitles.neutralItems`
                        )}
                        itemsCountType="info"
                        toPrint={toPrint}
                      />
                    </Col>
                  </Row>
                  <Row className="mrg-top-20">
                    <Col span={24}>
                      <MediaExposureItemsCollapse
                        items={mediaExposureItems?.negativeItems}
                        title={translate(
                          `${I18N_BASE_PATH}.collapsePanelTitles.negativeItems`
                        )}
                        itemsCountType="danger"
                        toPrint={toPrint}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <EmptyExecutionState
              statusCode={statusCode}
              message={source?.message || mediaExposure?.message}
              className="mrg-btm-20 mrg-top-0"
            />
          )}
        </div>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PjMediaProfileAndExposure;
