/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Alert from 'assets/images/alert.svg';
import TabTitle from 'pages/private/KYBCompany/CompanyDetails/components/TabTitle';
import Loading from 'components/Loading';
import NotAvailable from 'components/NotAvailable';
import './styles.less';
import { CompanySubtitle1, CompanySubtitle2, CompanyBody1 } from '../../atoms';
// eslint-disable-next-line import/no-cycle
import { CompanyTagging } from '../../molecules';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.companyInfo.businessIdentity.riskFactors';
const REASON = 'riskFactors.reason';

export function getUSALevel(level) {
  if (level === 'elevado') return 'elevated';
  if (level === 'alto') return 'high';
  if (level === 'crítico') return 'critical';
  if (['importante', 'relevante'].includes(level)) return 'relevant';
  return level;
}

const SanctionsContent = ({ riskIntelligenceItem, category }) => {
  const { t } = useTranslation();

  if (riskIntelligenceItem?.length === 0) return null;

  return (
    <div className="kyb-company-sanctions-content-container">
      <h5 className="kyb-company-sanctions-title">
        {`${t(`${I18N_BASE_PATH}.listOfSanctions`)} ${category}`}
      </h5>
      {riskIntelligenceItem?.map((item, index) => (
        <div key={index} className="kyb-company-sanctions-row">
          <div className="kyb-company-sanctions-column">
            <span className="kyb-company-column-title">
              {t(`${I18N_BASE_PATH}.program`)}
            </span>
            {item.properties.program ? (
              <span className="kyb-company-column-value">{item.properties.program}</span>
            ) : (
              <NotAvailable />
            )}
          </div>
          <div className="kyb-company-sanctions-column">
            <span className="kyb-company-column-title">
              {t(`${I18N_BASE_PATH}.date`)}
            </span>
            {item.properties.from_date ? (
              <span className="kyb-company-column-value">
                {item.properties.from_date}
              </span>
            ) : (
              <NotAvailable />
            )}
          </div>
          <div className="kyb-company-sanctions-column">
            <span className="kyb-company-column-title">Sources</span>
            {item.sourceName ? (
              <span className="kyb-company-column-value">{item.sourceName}</span>
            ) : (
              <NotAvailable />
            )}
          </div>
          <div className="kyb-company-sanctions-column">
            <span className="kyb-company-column-title">
              {t(`${I18N_BASE_PATH}.list`)}
            </span>
            {item.properties.list ? (
              <span className="kyb-company-column-value">{item.properties.list}</span>
            ) : (
              <NotAvailable />
            )}
          </div>
          <div className="kyb-company-sanctions-column">
            <span className="kyb-company-column-title">
              {t(`${I18N_BASE_PATH}.measures`)}
            </span>
            {item.properties.Measures ? (
              <span className="kyb-company-column-value">{item.properties.Measures}</span>
            ) : (
              <NotAvailable />
            )}
          </div>
          <div className="kyb-company-sanctions-column">
            <span className="kyb-company-column-title">
              {t(`${I18N_BASE_PATH}.legalBasis`)}
            </span>
            {item.properties['Legal Basis'] ? (
              <span className="kyb-company-column-value">
                {item.properties['Legal Basis']}
              </span>
            ) : (
              <NotAvailable />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

function makeRisksByLevel(t, payload, riskIntelligence) {
  const data = {};
  for (const item of payload) {
    const name = t(`${REASON}.${item.name}.name`);
    const source = t(`${REASON}.${item.name}.source`);
    const category = t(`${REASON}.${item.name}.category`);
    const levelUSA = getUSALevel(t(`${REASON}.${item.name}.level`));
    const level = t(`${REASON}.${item.name}.level`);
    const description = t(`${REASON}.${item.name}.description`);
    const riskInteligenceItems = riskIntelligence
      ? riskIntelligence?.filter((i) => i.properties.type === item.name)
      : [];
    if (!Array.isArray(data[levelUSA])) {
      data[levelUSA] = [];
    }
    data[levelUSA].push({
      name,
      source,
      level,
      levelUSA,
      category,
      description,
      key: item.name,
      value: item.value,
      riskInteligenceItems,
    });
  }

  const result = {
    critical: data.critical || [],
    high: data.high || [],
    elevated: data.elevated || [],
    relevant: data.relevant || [],
  };
  const isEmpty =
    !result.critical.length &&
    !result.high.length &&
    !result.elevated.length &&
    !result.relevant.length;
  return [result, isEmpty];
}

const Content = ({ payload }) => {
  const { t } = useTranslation();
  return (
    <div className="kyb-company-riskfactors-card kyb-company-print-break-avoid">
      <div className="kyb-company-riskfactors-group">
        <div>
          <CompanySubtitle2 level={5}>{t(`${I18N_BASE_PATH}.reason`)}</CompanySubtitle2>
          <CompanyBody1>{payload.name}</CompanyBody1>
        </div>
        <div className="kyb-company-riskfactors-ranking">
          {typeof payload.value !== 'number' ? null : (
            <>
              <CompanySubtitle2 level={5}>
                {t(`${I18N_BASE_PATH}.ranking`)}
              </CompanySubtitle2>
              <CompanyBody1>{payload.value}</CompanyBody1>
            </>
          )}
        </div>
        <div>
          <CompanySubtitle2 level={5}>{t(`${I18N_BASE_PATH}.level`)}</CompanySubtitle2>
          <CompanyTagging level={payload.levelUSA}>{payload.level}</CompanyTagging>
        </div>
        <div>
          <CompanySubtitle2 level={5}>{t(`${I18N_BASE_PATH}.category`)}</CompanySubtitle2>
          <CompanyBody1>{payload.source}</CompanyBody1>
        </div>
      </div>
      <div>
        <CompanySubtitle2 level={5}>
          {t(`${I18N_BASE_PATH}.description`)}
        </CompanySubtitle2>
        <CompanyBody1>{payload.description}</CompanyBody1>
      </div>
      <SanctionsContent
        riskIntelligenceItem={payload.riskInteligenceItems}
        category={payload.source}
      />
    </div>
  );
};

const Level = ({ className, data, level, render = true, ...rest }) => {
  if (!render) return null;
  return (
    <div className={classnames(className, 'kyb-company-riskfactors-level')} {...rest}>
      <CompanySubtitle1
        level="h4"
        className="kyb-company-riskfactors-title"
        render={data[level].length}
      >
        {level} ({data[level].length})
      </CompanySubtitle1>
      {data[level].map((item) => (
        <Content payload={item} />
      ))}
    </div>
  );
};

const RiskFactors = ({ payload, riskInteligence, itemsToExport, print = false }) => {
  const { t } = useTranslation();
  const { t: tr, ready } = useTranslation('risk-factors');
  if (itemsToExport?.value === false) return null;
  if (!ready) {
    return <Loading />;
  }
  const [data, isEmpty] = makeRisksByLevel(tr, payload, riskInteligence);
  const levels = Object.keys(data);
  if (isEmpty) {
    return <NotAvailable card />;
  }

  return (
    <section className="kyb-company-riskfactors-main">
      <TabTitle
        icon={Alert}
        title={t(`${I18N_BASE_PATH}.title`, 'Risk factors')}
        render={!print}
      />
      <div className="kyb-company-riskfactors-content">
        {levels.map((level) => (
          <Level render={data[level].length} data={data} level={level} />
        ))}
      </div>
    </section>
  );
};

Level.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  level: PropTypes.string.isRequired,
  render: PropTypes.bool.isRequired,
};
RiskFactors.propTypes = {
  payload: PropTypes.array.isRequired,
  print: PropTypes.bool.isRequired,
};
Content.propTypes = RiskFactors.propTypes;

export default RiskFactors;
