const edgeType = 'smoothstep';

const listToTree = (items) => {
  if (!items || !Array.isArray(items)) return [];

  const itemsWithId = items.map((item, index) => ({
    ...item,
    id: index.toString(),
  }));

  const itemsWithParentId = itemsWithId.map((item, index) => {
    const parentId = itemsWithId
      .filter((d) => d.id < index && d.level === item.level - 1)
      .reverse()
      .at(0)?.id;

    return {
      ...item,
      parentId,
    };
  });

  itemsWithParentId.forEach((f) => {
    f.children = itemsWithParentId.filter((g) => g.parentId === f.id).map((h) => h.id);
  });

  const nodes = [];
  const edges = [];

  const itemsMap = itemsWithParentId.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  const buildTree = (item, posX = 0, posY = 0, isParent = true) => {
    const isLeaf = !item.children || item.children.length === 0;
    nodes.push({
      id: item.id,
      type: 'selectorNode',
      data: {
        label: item?.name,
        name: item?.name,
        address: item?.address?.fullAddress,
        country: item?.country,
        entityType: item?.relationType?.isUltimateParent ? 'company' : 'subsidiary',
        status: item?.status,
        latestAnnualAccounts: item?.latestAnnualAccounts,
        isLeaf,
        isParent,
      },
      position: { x: posX, y: posY },
    });

    if (item.children) {
      item.children.forEach((childId, index) => {
        const childItem = itemsMap[childId];
        const childPosX = posX + 500 * index;
        const childPosY = posY + 300;
        edges.push({
          id: `e-${item.id}-${childId}`,
          source: item.id,
          target: childId,
          type: edgeType,
          animated: true,
        });
        buildTree(childItem, childPosX, childPosY, false);
      });
    }
  };

  const rootItem = itemsWithParentId?.[0];

  if (rootItem) {
    buildTree(rootItem);
  }

  return [...nodes, ...edges];
};

export default listToTree;
