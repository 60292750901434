import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Col, Modal, Row } from 'antd';

import NPSIcon from 'assets/images/NPS.svg';

import { rudderAnalytics } from 'hooks/rudderstack';
import { useAuth } from 'hooks/auth';
import Cookies from 'js-cookie';
import tenantWithCS from './tenantsWithCS.json';
import NPSTextInfos from './NPSTextInfos.json';

const ModalNPSNotification = () => {
  const language = useMemo(() => {
    return Cookies.get('__Secure-CAF_language');
  }, []);

  const { user } = useAuth();

  const [visible, setVisible] = useState(false);

  const closeModal = useCallback(() => {
    setVisible(false);
  }, []);

  const NPSInfos = useMemo(() => {
    let title = '';
    let description = '';
    let footer = '';
    let button = '';

    NPSTextInfos.map((info) => {
      if (info.language === language) {
        title = info.title;
        description = info.description;
        footer = info.footer;
        button = info.button;
      }
    });

    if (!title) {
      title = NPSTextInfos[0].title;
    }

    if (!description) {
      description = NPSTextInfos[0].description;
    }

    if (!footer) {
      footer = NPSTextInfos[0].footer;
    }

    if (!button) {
      button = NPSTextInfos[0].button;
    }

    return { title, description, footer, button };
  }, [language]);

  useEffect(() => {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const monthOfYear = today.getMonth();
    const fullYear = today.getFullYear();

    const hasShownToday = localStorage.getItem(`modalShown_${dayOfMonth}`);
    const isMonthAndYear = monthOfYear === 11 && fullYear === 2024; // dezembro de 2024
    const daysToShow = [2, 4, 5, 6, 9, 10, 11, 12, 13];

    // ! QA test
    const daysToShowBetaTest = [2, 4, 5, 6, 9, 10, 11, 12, 13];
    const betaTenants = [
      '7c4de253-88fc-40e5-ae98-39974e5885f6',
      'fc72cea1-6691-4ad9-a5a4-2d46fbbbaa2d',
    ];

    if (
      !hasShownToday &&
      daysToShowBetaTest.includes(dayOfMonth) &&
      isMonthAndYear &&
      betaTenants.includes(user?.accountData?.tenantId) &&
      process.env.REACT_APP_ENV === 'beta'
    ) {
      setVisible(true);
      localStorage.setItem(`modalShown_${dayOfMonth}`, 'true');
    }

    // verifica pelo localStorage se o modal já foi mostrado hoje e se é um dos dias da semana/ano/mes que o modal deve ser mostrado
    if (
      !hasShownToday &&
      daysToShow.includes(dayOfMonth) &&
      isMonthAndYear &&
      process.env.REACT_APP_ENV === 'prod'
    ) {
      setVisible(true);
      localStorage.setItem(`modalShown_${dayOfMonth}`, 'true');
    }
  }, [user]);

  const url = useMemo(() => {
    return tenantWithCS.includes(user?.accountData?.tenantId)
      ? 'https://pt.surveymonkey.com/r/nps_caf_2024_2_2'
      : 'https://pt.surveymonkey.com/r/nps_caf_2024_2_1';
  }, [user]);

  return (
    <Modal visible={visible} width={680} onCancel={closeModal} footer={null} centered>
      <Row justify="center" align="middle" className="mrg-vertical-20">
        <Col>
          <img src={NPSIcon} alt="" />
        </Col>
      </Row>
      <Row justify="center" className="mrg-vertical-20">
        <Col>
          <span className="text-bold h1">{NPSInfos.title}</span>
        </Col>
      </Row>
      <Row justify="center" style={{ width: 480, textAlign: 'center', margin: 'auto' }}>
        <Col span={24}>
          <span className="mrg-top-20 h5">{NPSInfos.description}</span>
        </Col>
      </Row>
      <Row justify="center" style={{ width: 480, textAlign: 'center', margin: 'auto' }}>
        <Col span={24}>
          <p className="mrg-top-20 h5">{NPSInfos.footer}</p>
        </Col>
      </Row>
      <Row
        className="mrg-top-20 text-bold"
        justify="center"
        style={{
          width: 480,
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: 64,
        }}
      >
        <Col span={24} style={{ maxWidth: 580, margin: 'auto' }}>
          <Button
            type="primary"
            onClick={() => {
              rudderAnalytics.track('NPS Accessed', {
                user: user?.username,
                tenantId: user?.accountData?.tenantId,
                date: new Date().toISOString(),
              });
              window.open(url, '_blank');
              closeModal();
            }}
          >
            {NPSInfos.button}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalNPSNotification;
