import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconGroupStructure from 'assets/images/company/group-structure.svg';
import TabTitle from 'pages/private/KYBCompany/CompanyDetails/components/TabTitle';
import TreeTableCompanyLinks from 'pages/private/KYBCompany/CompanyDetails/components/TabsComponent/CompanyLink/components/TreeTable';

import React, { useState, Suspense, useRef } from 'react';
import ReactFlow, { isNode, Controls, ControlButton } from 'react-flow-renderer';
import dagre from 'dagre';

import { Button } from 'antd';
import { DoubleArrow, BulletListText, Download2 } from '@combateafraude/icons/general';
import Loading from 'components/Loading';
import { CompanyGroupSummary, CompanySummaryCounterCard } from '../../molecules';

import './styles.less';
import CustomNode from '../UboStructureReport/CustomNode';
import listToTree from './makeTreeGroupStructure';

const PAGE = 'pages.private.KYBCompany.pages';
const BASE = `${PAGE}.companyDetails.subPages.companyLinks.groupStructure`;
const STAT = `${BASE}.cards.groupStructureStatistics`;
const SUMMARY = `${BASE}.cards.groupStructureSummary`;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 400;
const nodeHeight = 250;

const getLayoutedElements = (elements, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};

const nodeTypes = {
  selectorNode: CustomNode,
};

const GroupStructure = ({ payload, itemsToExport, disabled, print = false }) => {
  const { t } = useTranslation();

  const [isTreeView, setIsTreeView] = useState(false);

  const handleToggle = () => {
    setIsTreeView(!isTreeView);
  };

  const layoutedElementsRef = useRef([]);

  if (disabled || itemsToExport?.value === false) {
    return null;
  }

  const summaryCounter = [
    {
      text: t(`${STAT}.companies`, 'Companies'),
      count: payload.countCompanies,
    },
    {
      text: t(`${STAT}.countries`, 'Countries'),
      count: payload.countCountries,
    },
  ];

  const groupSummary = [
    {
      text: t(`${SUMMARY}.columns.holdingCompany`),
      info: payload.holdingCompanyName,
    },
    {
      text: t(`${SUMMARY}.columns.ultimateHoldingCompany`),
      info: payload.ultimateHoldingCompanyName,
    },
    {
      text: t(`${SUMMARY}.columns.ownershipStatus`),
      info: payload.ownershipStatus,
    },
  ];

  const nodesTree = getLayoutedElements(listToTree(payload.groupStructure));

  if (nodesTree.length > 0) {
    layoutedElementsRef.current = nodesTree;
  }

  return (
    <section className="kyb-company-groupstructure-main">
      <TabTitle
        icon={IconGroupStructure}
        title={t(`${BASE}.title`, 'Group structure')}
        render={!print}
      />
      <div className="kyb-company-groupstructure-summary-area">
        <CompanySummaryCounterCard payload={summaryCounter} />
        <CompanyGroupSummary
          payload={groupSummary}
          className="kyb-company-groupstructure-group-summary"
        />
      </div>
      <div className="kyb-ubo-structure-report-button-container">
        <Button
          type="primary"
          shape="default"
          onClick={handleToggle}
          className={`kyb-more-relationship-toggle-button ${isTreeView ? 'toggled' : ''}`}
        >
          <span className="toggle-icons">
            <BulletListText className="icon-left" />
            <DoubleArrow className="icon-right" />
          </span>
        </Button>
      </div>
      {!isTreeView ? (
        <TreeTableCompanyLinks groupStructure={payload.groupStructure} />
      ) : null}
      {isTreeView ? (
        <Suspense fallback={<Loading />}>
          <div className="kyb-ubo-structure-container" style={{ height: 700 }}>
            <ReactFlow
              elements={layoutedElementsRef.current}
              connectionLineType="smoothstep"
              nodeTypes={nodeTypes}
            >
              <Controls
                position="top-right"
                showInteractive={false}
                style={{ height: '30px' }}
              />
            </ReactFlow>
          </div>
        </Suspense>
      ) : null}
    </section>
  );
};

GroupStructure.propTypes = {
  payload: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  itemsToExport: PropTypes.object.isRequired,
  print: PropTypes.bool.isRequired,
};

GroupStructure.defaultProps = {
  disabled: false,
};

export default GroupStructure;
