const edgeType = 'smoothstep';

export function getFieldSharePercentage(payload) {
  const fieldRelationship = payload.relationships?.find(
    (rel) => rel.name === payload.field
  );
  if (!fieldRelationship) return null;
  const shareAttribute = fieldRelationship.data
    .reverse()
    .find((d) => Object.keys(d.attributes).includes('shares'));
  const shares = shareAttribute?.attributes?.shares || [];
  return shares.length ? shares[0].percentage : null;
}

const listToTree = (data, summaryData) => {
  let array = [];
  array[0] = {
    id: data?.query?.data?.businessIdentityId,
    name: summaryData?.title,
    address: summaryData?.address,
    country: summaryData?.priorityCountry,
    entityType: 'company',
    status: summaryData?.statusCode,
    identifiers: [{ value: summaryData?.identifier }],
    latestFinancial: null,
    children: [...new Set(data?.data?.data?.map((d) => d?.path[0]?.entity?.id))],
    isParent: true,
  };

  data?.data?.data?.forEach((d) => {
    d?.path.forEach((p, index) => {
      const percentage = getFieldSharePercentage(p);
      array = [
        ...array,
        {
          name: p?.entity?.name,
          address: p?.entity?.addresses[0],
          country: p?.entity?.countries,
          entityType: p?.entity?.entityType,
          status: p?.entity?.latestStatus?.status,
          latestFinancial: null,
          id: p?.entity?.id,
          identifiers: p?.entity?.identifiers,
          percentage: percentage ? `(${percentage}%)` : '',
          field: p?.field,
          children: d?.path[index + 1] ? [d?.path[index + 1]?.entity?.id] : [],
        },
      ];
    });
  });

  const object = {};
  array?.forEach((a) => {
    if (object[a?.id]) {
      object[a?.id].children = [...object[a?.id].children, ...a?.children];
      return;
    }
    object[a?.id] = a;
    return null;
  });

  const nodes = [];
  const edges = [];

  const buildTree = (node, posX = 0, posY = 0) => {
    const isLeaf = node.children.length === 0;
    nodes.push({
      id: node.id,
      type: 'selectorNode',
      data: {
        label: node.name,
        name: node.name,
        address: node.address,
        country: node.country,
        entityType: node.entityType,
        field: node.field,
        percentage: node.percentage,
        status: node.status,
        latestAnnualAccounts: node.latestFinancial,
        identifiers: node.identifiers,
        isParent: node.isParent,
        isUBO: true,
        isLeaf,
      },
      position: { x: posX, y: posY },
    });

    node.children.forEach((childId, index) => {
      const childNode = object[childId];
      const childPosX = posX + 500 * (index + 1);
      const childPosY = 0;
      edges.push({
        id: `e-${node.id}-${childId}`,
        source: node.id,
        target: childId,
        type: edgeType,
        animated: true,
      });
      buildTree(childNode, childPosX);
    });
  };

  buildTree(object[array[0].id]);

  console.log('nodes UBO', nodes);

  return [...nodes, ...edges];
};

export default listToTree;
