import React, { Suspense, useState, useEffect, useCallback, useRef } from 'react';
import { useFetch } from 'services/hooks';
import ReactFlow, { MiniMap, Controls, isNode, ControlButton } from 'react-flow-renderer';
import dagre from 'dagre';

import { Button } from 'antd';
import { DoubleArrow, BulletListText, Download2 } from '@combateafraude/icons/general';
import TabTitle from 'pages/private/KYBCompany/CompanyDetails/components/TabTitle';
import { useTranslation } from 'react-i18next';
import KYBPeople from 'assets/images/company/people.svg';
import Loading from 'components/Loading';
import NotAvailable from 'components/NotAvailable';
import RelationshipList from 'pages/private/KYBCompany/CompanyDetailsV2/components/BusinessIdentityRelationships/RelationshipList';

import CustomNode from './CustomNode';

import './styles.less';
import listToTree from './makeTreeUBO';

const PAGE = 'pages.private.KYBCompany.pages';
const BASE = `${PAGE}.companyDetails.subPages.uboStructureReport`;

const nodeTypes = {
  selectorNode: CustomNode,
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 400;
const nodeHeight = 250;

const getLayoutedElements = (elements, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};

const UboStructureReport = ({ transactionId, peopleData, summaryData }) => {
  const { t: translate } = useTranslation();
  const { ready } = useTranslation('kyb-business-identity-relationship');

  const { post: postMethod, loading: fetchLoading, error } = useFetch();
  const [apiResponse, setApiResponse] = useState(null);

  const [isTreeView, setIsTreeView] = useState(false);

  const handleToggle = () => {
    setIsTreeView(!isTreeView);
  };
  const layoutedElementsRef = useRef([]);

  const loading = fetchLoading;
  const { entityType, businessIdentityId } = peopleData.businessIdentity;

  const fetchAPISayari = async (offset = 0) => {
    return postMethod({
      url: '/company-ubo',
      payload: {
        transactionId,
        parameters: {
          businessIdentityId,
          filters: {
            limit: 50,
            offset,
          },
        },
      },
    });
  };

  useEffect(() => {
    async function fetchData() {
      if (!businessIdentityId || !transactionId) return [];
      const response = await fetchAPISayari();
      layoutedElementsRef.current = getLayoutedElements(
        listToTree(response, summaryData)
      );
      setApiResponse(response.data);
    }

    fetchData();
  }, [businessIdentityId, transactionId]);

  return (
    <section className="kyb-company-ubo-structure-report-main">
      <TabTitle
        icon={KYBPeople}
        title={translate(`${BASE}.title`, 'UBO Structure Report')}
      />

      <div className="kyb-ubo-structure-report-button-container">
        <Button
          type="primary"
          shape="default"
          onClick={handleToggle}
          className={`kyb-more-relationship-toggle-button ${isTreeView ? 'toggled' : ''}`}
        >
          <span className="toggle-icons">
            <BulletListText className="icon-left" />
            <DoubleArrow className="icon-right" />
          </span>
        </Button>
      </div>
      {loading && <Loading />}
      {!error ? null : <NotAvailable card />}
      {!error && !loading && !isTreeView ? (
        <RelationshipList payload={apiResponse?.data} entityType={entityType} />
      ) : null}
      {!error && !loading && isTreeView ? (
        <Suspense fallback={<Loading />}>
          <div className="kyb-ubo-structure-container" style={{ height: 700 }}>
            <ReactFlow elements={layoutedElementsRef.current} nodeTypes={nodeTypes}>
              <Controls
                position="top-right"
                showInteractive={false}
                style={{ height: '30px' }}
              />
            </ReactFlow>
          </div>
        </Suspense>
      ) : null}
    </section>
  );
};

export default UboStructureReport;
